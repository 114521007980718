import { useSearchParams } from 'react-router-dom';

import {
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Heading,
  Select,
  Spinner,
} from '@interskillar/ui';
import { getDefaultImage } from 'utils/profile';
import { MentorsResponse, useMentors, useMentorsMetadata, useMentorsOptions } from 'services/api';
import { useTranslate } from '@interskillar/localization';

const SingleValue = ({ label, value }: { label: string; value: string | number | null }) => {
  if (!value) {
    return null;
  }

  return (
    <div className="flex items-center">
      <div className="text-subtle mr-2 font-medium">{label}:</div>
      <div className="font-bold">{value}</div>
    </div>
  );
};

const BadgeItem = ({ label, values }: { label: string; values: string[] }) => {
  if (!values?.length) {
    return null;
  }

  return (
    <div>
      <div className="text-subtle font-medium">{label}:</div>
      <div className="flex flex-wrap gap-1">
        {values.map((sector) => (
          <Badge key={sector}>{sector}</Badge>
        ))}
      </div>
    </div>
  );
};

const MentorMoreInfoModal = ({ mentor }: { mentor: MentorsResponse['records'][number] }) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost" className="underline">
          More info
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {mentor.fields.first_name as unknown as string} {mentor.fields.last_name as unknown as string}
          </DialogTitle>
        </DialogHeader>

        <SingleValue label="Email" value={mentor.fields.email as unknown as string} />
        <SingleValue label="Phone number" value={mentor.fields.phone_number as unknown as string} />
        <SingleValue
          label="Experience as mentor"
          value={mentor.fields.experience_as_mentor?.[0] as unknown as string}
        />
        <SingleValue
          label="Available hours per month"
          value={mentor.fields.hours_per_month_availability as unknown as number}
        />
        <SingleValue label="Location" value={mentor.fields.location?.[0] as unknown as string} />
        <SingleValue label="Mentoring preference" value={mentor.fields.mentor_preference?.[0] as unknown as string} />

        <BadgeItem label="Sectors" values={mentor.fields.primary_sector as unknown as string[]} />
        <BadgeItem label="Tools" values={mentor.fields.tools as unknown as string[]} />
        <BadgeItem label="Domains" values={mentor.fields.domain_of_knowledge as unknown as string[]} />
      </DialogContent>
    </Dialog>
  );
};

const MentorCard = ({ mentor }: { mentor: MentorsResponse['records'][number] }) => {
  const profilePictureUrl =
    (mentor.fields.profile_picture?.[0] as { url?: string })?.url ??
    getDefaultImage(
      (mentor.fields.first_name as unknown as string) || '',
      (mentor.fields.last_name as unknown as string) || '',
    );

  return (
    <Card>
      <CardHeader>
        <div className="flex gap-2">
          <Avatar size="small" src={profilePictureUrl} alt="Profile picture" />
          <div>
            <div className="font-medium">
              {mentor.fields.first_name as unknown as string} {mentor.fields.last_name as unknown as string}
            </div>
            <div className="text-subtle font-bold">{mentor.fields.job_title as unknown as string}</div>
          </div>
        </div>
      </CardHeader>
      <CardContent className="space-y-2">
        <SingleValue label="Years of experience" value={mentor.fields.years_of_experience?.[0] as unknown as string} />
        <BadgeItem label="Sectors" values={mentor.fields.primary_sector as unknown as string[]} />
        <BadgeItem label="Tools" values={mentor.fields.tools as unknown as string[]} />
        <BadgeItem label="Domains" values={mentor.fields.domain_of_knowledge as unknown as string[]} />

        <div className="mt-2 flex justify-center">
          <MentorMoreInfoModal mentor={mentor} />
        </div>
      </CardContent>
    </Card>
  );
};

const useFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filtersParam = searchParams.get('filters');
  const filters = filtersParam ? (JSON.parse(filtersParam) as Record<string, string[] | string>) : {};

  const setFilter = (key: string, value: string | string[] | null) => {
    if (value === null) {
      delete filters[key];
    } else {
      if (Array.isArray(value) && value.length === 0) {
        delete filters[key];
      } else {
        filters[key] = value;
      }
    }

    setSearchParams((sp) => {
      if (Object.keys(filters).length === 0) {
        sp.delete('filters');
        return sp;
      }

      sp.set('filters', JSON.stringify(filters));

      return sp;
    });
  };

  return {
    filters,
    setFilter,
  };
};

const SelectFilter = ({
  label,
  queryKey,
  isMulti,
  options,
}: {
  label: string;
  queryKey: string;
  isMulti: boolean;
  options: { label: string; value: string }[];
}) => {
  const { filters, setFilter } = useFilters();

  const filterValue = filters[queryKey];
  const arrayVal = Array.isArray(filterValue) ? filterValue : [filterValue];

  const value = arrayVal.map((v) => options.find((o) => o.value === v)).filter(Boolean);

  return (
    <div className="min-w-[200px] max-w-sm flex-1">
      <Select
        label={label}
        isMulti={isMulti}
        isRounded={false}
        isClearable
        value={value}
        options={options}
        onChange={(
          selected:
            | {
                label: string;
                value: string;
              }[]
            | {
                label: string;
                value: string;
              }
            | null,
        ) => {
          if (Array.isArray(selected)) {
            setFilter(
              queryKey,
              selected.map((s) => s.value),
            );
          } else if (selected) {
            setFilter(queryKey, selected.value);
          } else {
            setFilter(queryKey, null);
          }
        }}
      />
    </div>
  );
};

const ENABLED_FILTERS = [
  { tkey: 'mentors.filter.years_of_experience', value: 'years_of_experience' },
  { tkey: 'mentors.filter.mentor_preference', value: 'mentor_preference' },
  { tkey: 'mentors.filter.tools', value: 'tools' },
  { tkey: 'mentors.filter.domain_of_knowledge', value: 'domain_of_knowledge' },
  { tkey: 'mentors.filter.sector', value: 'primary_sector' },
];

const Filters = () => {
  const metadataQuery = useMentorsMetadata();
  const optionsQuery = useMentorsOptions();
  const t = useTranslate();

  return (
    <div className="mb-4 flex flex-wrap gap-4">
      {ENABLED_FILTERS?.map((filter) => {
        const metadata = metadataQuery.data?.find((d) => d.description === filter.value);

        if (!metadata) {
          return null;
        }

        if (metadata.type === 'multipleRecordLinks') {
          const opts =
            optionsQuery.data
              ?.find((d) => d.id === metadata.options?.linkedTableId)
              ?.localizations.map((loc) => ({
                label: loc.text,
                value: loc.id,
              })) ?? [];

          return (
            <SelectFilter
              key={metadata.name}
              isMulti={
                'prefersSingleRecordLink' in (metadata.options || {}) && !metadata.options?.prefersSingleRecordLink
              }
              label={t(filter.tkey)}
              queryKey={metadata.id}
              options={opts}
            />
          );
        }

        // eslint-disable-next-line no-console
        console.error('Unknown filter type', { metadata, filter });

        return null;
      })}
    </div>
  );
};

const Content = () => {
  const { filters } = useFilters();

  const mentorsMetadataQuery = useMentorsMetadata();
  const mentorsOptionsQuery = useMentorsOptions();

  const mentorsQuery = useMentors({
    filters: Object.keys(filters)
      .map((key) => {
        const table = mentorsMetadataQuery.data?.find((d) => d.id === key)?.options?.linkedTableId ?? '';

        if (Array.isArray(filters[key])) {
          return (filters[key] as string[]).map((v) => ({
            name: key,
            operator: 'equals',
            value: mentorsOptionsQuery.data?.find((d) => d.id === table)?.localizations.find((l) => l.id === v)
              ?.fieldUID,
          }));
        }

        return {
          name: key,
          operator: 'equals',
          value: mentorsOptionsQuery.data?.find((d) => d.id === table)?.localizations.find((l) => l.id === filters[key])
            ?.fieldUID,
        };
      })
      .flat(Infinity) as {
      name: string;
      operator: string;
      value: string | string[];
    }[],
  });

  if (mentorsQuery.isLoading || mentorsMetadataQuery.isLoading || mentorsOptionsQuery.isLoading) {
    return (
      <div className="flex h-52 items-center justify-center">
        <Spinner size="large" />
      </div>
    );
  }

  const allPages = (mentorsQuery.data?.pages ?? []).map((p) => p.records).flat(1);

  return (
    <div>
      <Filters />

      {allPages.length > 0 ? (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          {allPages.map((mentor) => {
            return <MentorCard key={mentor.id} mentor={mentor} />;
          })}
        </div>
      ) : (
        <div className="flex h-52 items-center justify-center">
          <div className="text-subtle">No mentors found</div>
        </div>
      )}

      {mentorsQuery.hasNextPage && (
        <div className="mt-4 flex justify-center">
          <Button isLoading={mentorsQuery.isFetchingNextPage} onClick={() => mentorsQuery.fetchNextPage()}>
            Load more
          </Button>
        </div>
      )}
    </div>
  );
};

export const MentorsPage = () => {
  return (
    <div className="pb-14">
      <div className="flex items-center justify-between">
        <Heading iconName="message" className="mb-4">
          Mentors
        </Heading>
      </div>

      <Content />
    </div>
  );
};
