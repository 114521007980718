import { ReactNode, useState } from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';

import { type TalentPassportResponse } from 'services/api';
import { handleKeyDown } from 'utils/elements';

type MaxWidthTextProps = {
  text: string;
  x: number;
  y: number;
  maxWidth: number;
  lineHeight?: number;
  fontSize?: number;
  isSelected?: boolean;
  onClick: () => void;
};

const MaxWidthText = ({
  text,
  x,
  y,
  maxWidth,
  lineHeight = 11,
  fontSize = 10,
  isSelected,
  onClick,
}: MaxWidthTextProps) => {
  const words = text.split(' ');
  const tspans: ReactNode[] = [];
  let currentLine = '';
  let currentLineLength = 0;

  for (const word of words) {
    const wordLength = word.length * (fontSize * 0.6);

    if (currentLineLength + wordLength <= maxWidth) {
      currentLine += `${word} `;
      currentLineLength += wordLength;
    } else {
      tspans.push(
        <tspan x={x} dy={lineHeight} key={tspans.length}>
          {currentLine.trim()}
        </tspan>,
      );
      currentLine = `${word} `;
      currentLineLength = wordLength;
    }
  }

  tspans.push(
    <tspan x={x} dy={lineHeight} key={tspans.length}>
      {currentLine.trim()}
    </tspan>,
  );

  return (
    <text
      x={x - 5}
      y={y - 20}
      fontSize={fontSize}
      fontWeight={isSelected ? 'bold' : 'normal'}
      className="outline-none"
      fill="white"
      aria-selected={isSelected}
      textAnchor="middle"
      cursor="pointer"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={handleKeyDown(onClick)}
    >
      {tspans}
    </text>
  );
};

type CustomLabelProps = {
  x: number;
  y: number;
  cx: number;
  cy: number;
  category: string;
  payload: { value: string; index: number };
  index: number;
  selectedIndex: number;
  onClick: (index: number) => void;
};

const CustomLabel = ({ x, y, cx, cy, payload, index, selectedIndex, onClick }: CustomLabelProps) => {
  const maxWidth = 100;
  const lineHeight = 12;
  const fontSize = 12;

  const isSelected = index === selectedIndex;

  return (
    <MaxWidthText
      y={y + (y - cy) / 2}
      x={x + (x - cx) / 2}
      text={`${payload.value}`}
      maxWidth={maxWidth}
      lineHeight={lineHeight}
      fontSize={fontSize}
      isSelected={isSelected}
      onClick={() => onClick(payload.index)}
    />
  );
};

export const AbilitiesChart = ({ data }: { data: TalentPassportResponse }) => {
  const [displayedCategoryIndex, setDisplayedCategoryIndex] = useState(0);

  const chartData = data.categoriesWithDetails
    .map((item) => ({
      label: item.localizedCategoryName,
      value: item.numericalValue,
    }))
    .sort((a, b) => b.value - a.value);

  const selectedCategoryOption = chartData[displayedCategoryIndex];
  const selectedCategory = data.categoriesWithDetails.find(
    (item) => item.localizedCategoryName === selectedCategoryOption.label,
  )!;

  return (
    <div>
      <ResponsiveContainer width="99%" height={375}>
        <RadarChart cx="50%" cy="50%" outerRadius="45%" data={chartData}>
          <PolarGrid />
          <PolarAngleAxis
            dataKey="label"
            tick={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <CustomLabel onClick={setDisplayedCategoryIndex} selectedIndex={displayedCategoryIndex} />
            }
          />
          <PolarRadiusAxis domain={[0, 10]} />
          <Radar dataKey="value" stroke="#fb91e4" fill="#fb91e4" fillOpacity={0.6} />
        </RadarChart>
      </ResponsiveContainer>

      {selectedCategory && (
        <div className="bg-brand-pink/60 rounded-2xl p-4">
          <h3 className="mb-2 text-xl font-bold">{selectedCategory.localizedCategoryName}</h3>

          <p className="prose text-primary mb-2">{selectedCategory.localizedDescription}</p>

          <ul className="list-disc space-y-1 pl-5">
            {selectedCategory.tasks.map((task) => (
              <li key={task.localizeDescription}>{task.localizeDescription}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
