import { Link } from 'react-router-dom';
import cx from 'clsx';

import { Icon, IconProps } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';

type TileWithIconProps = {
  icon: IconProps['name'];
  title: string;

  href: string;
};

export const TileWithIcon = ({ icon, title, href }: TileWithIconProps) => {
  const t = useTranslate();
  const isDisabled = false; // revisit
  const Component = isDisabled ? 'div' : Link;

  return (
    <Component
      to={href}
      aria-disabled={isDisabled}
      className={cx('bg-brand flex items-center rounded-3xl border border-gray-400 p-2', {
        'cursor-pointer transition hover:shadow-[0px_4px_12px] hover:shadow-gray-800': !isDisabled,
        'cursor-not-allowed': isDisabled,
      })}
    >
      <div className="flex flex-1 items-center">
        <div
          className={cx('rounded-2xl p-6', {
            'bg-brand-pink text-white': !isDisabled,
            'bg-gray-300 text-gray-500': isDisabled,
          })}
        >
          <Icon name={icon} className="text-contrast text-3xl" />
        </div>
        <div className="ml-4 text-lg font-medium">{t(title)}</div>
      </div>

      <Icon name="chevron-right" className="ml-12 text-3xl" />
    </Component>
  );
};
