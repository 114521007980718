import { useAuth } from 'oidc-react';
import { useQuery } from '@tanstack/react-query';

export const useIsBackendDown = () => {
  const { userManager } = useAuth();

  return useQuery({
    queryKey: ['isBackendDown'],
    queryFn: async () => {
      try {
        await userManager.metadataService.getAuthorizationEndpoint();
        return false;
      } catch (error) {
        return true;
      }
    },
  });
};
