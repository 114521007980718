import { Navigate } from 'react-router-dom';

import {
  PageLoader,
  Separator,
  Heading,
  Button,
  Dialog,
  DialogTrigger,
  DialogTitle,
  DialogContent,
  DialogFooter,
} from '@interskillar/ui';
import { FormattedMessage, useTranslate } from '@interskillar/localization';
import {
  CAREER_ORIENTATION_PERSONALITY_RESULTS_PAGE,
  TOOLBOX_PAGE,
  TALENT_PASSPORT_PAGE,
  PERSONALITY_SURVEY_PAGE,
  MOTIVATIONS_SURVEY_PAGE,
  EXPERIENCE_SURVEY_PAGE,
  ALL_SET_PAGE,
} from 'consts/pages';
import { getLatestSurvey } from 'utils/storage';
import { useToggle } from 'utils/hooks';
import {
  useExperienceSurvey,
  useMotivationSurvey,
  usePendingInvitations,
  usePersonalitySurveyStatus,
  useResetAssessments,
  useSectorsOfInterest,
  useUserProfile,
} from 'services/api';
import { useFeedbackModal } from 'services/feedback';
import { PendingInvitations } from 'components/PendingInvitations';

import { TileWithIcon } from './TileWithIcon';
import { StartInstructions } from './StartInstructions';

export function CareerOrientationPage() {
  const t = useTranslate();
  const userProfileQuery = useUserProfile();
  const personalitySurveyStatusQuery = usePersonalitySurveyStatus();
  const motivationSurveyQuery = useMotivationSurvey();
  const experienceSurveyQuery = useExperienceSurvey();
  const sectorsSurveyQuery = useSectorsOfInterest();
  const resetAssessmentsMutation = useResetAssessments();
  const { openFeedbackModal } = useFeedbackModal();
  const [isModalOpen, toggleIsModalOpen] = useToggle();

  const isPersonalityCompleted = personalitySurveyStatusQuery.data?.basicInfo.status === 'Completed';
  const isMotivationsSurveyCompleted = motivationSurveyQuery.data !== null;
  const isExperienceSurveyCompleted = experienceSurveyQuery.data !== null;
  const isSectorsSurveyCompleted = sectorsSurveyQuery.data !== null;

  const pendingInvitationsQuery = usePendingInvitations();

  if (
    personalitySurveyStatusQuery.isLoading ||
    motivationSurveyQuery.isLoading ||
    experienceSurveyQuery.isLoading ||
    sectorsSurveyQuery.isLoading ||
    pendingInvitationsQuery.isLoading
  ) {
    return <PageLoader />;
  }

  if (pendingInvitationsQuery.data?.length) {
    return (
      <div>
        <Heading iconName="career-orientation-icon" className="mb-4">
          {t('orientation.title')}
        </Heading>

        <PendingInvitations />
      </div>
    );
  }

  const areAllEmpty =
    !isPersonalityCompleted &&
    !isMotivationsSurveyCompleted &&
    !isExperienceSurveyCompleted &&
    !isSectorsSurveyCompleted;

  if (areAllEmpty && personalitySurveyStatusQuery.data?.basicInfo.status === 'NotStarted') {
    return (
      <div className="max-w-xl pb-14">
        <Heading iconName="career-orientation-icon" className="mb-4">
          {t('orientation.title')}
        </Heading>

        <StartInstructions />
      </div>
    );
  }

  const handleResetConfirm = () => {
    resetAssessmentsMutation
      .mutateAsync()
      .then(() => {
        toggleIsModalOpen(false);
      })
      .catch(() => {});
  };

  const latestSurvey = getLatestSurvey(userProfileQuery.data!.id);

  if (latestSurvey === 'personality' && personalitySurveyStatusQuery.data?.basicInfo.status !== 'Completed') {
    return <Navigate to={PERSONALITY_SURVEY_PAGE.path} />;
  }

  if (latestSurvey === 'motivation') {
    return <Navigate to={MOTIVATIONS_SURVEY_PAGE.path} />;
  }

  if (latestSurvey === 'experience') {
    return <Navigate to={EXPERIENCE_SURVEY_PAGE.path} />;
  }

  if (latestSurvey === 'complete') {
    return <Navigate to={ALL_SET_PAGE.path} />;
  }

  if (!isMotivationsSurveyCompleted || !isSectorsSurveyCompleted) {
    return <Navigate to={MOTIVATIONS_SURVEY_PAGE.path} />;
  }

  if (!isExperienceSurveyCompleted) {
    return <Navigate to={EXPERIENCE_SURVEY_PAGE.path} />;
  }

  if (!isPersonalityCompleted) {
    return <Navigate to={PERSONALITY_SURVEY_PAGE.path} />;
  }

  return (
    <div className="max-w-xl pb-14">
      <Heading iconName="career-orientation-icon" className="mb-4">
        {t('orientation.title')}
      </Heading>

      <p className="prose prose-sm text-primary md:prose-base mb-4">
        <FormattedMessage
          id="orientation.subtitle"
          values={{
            br: <br />,
          }}
        />
      </p>

      <Heading type="h2" font="body" weight="medium" className="mb-4">
        {t('orientation.results_subtitle')}
      </Heading>

      <div className="grid grid-rows-1 gap-6">
        <TileWithIcon
          title="orientation.personality_results_card"
          icon="fingerprint"
          href={CAREER_ORIENTATION_PERSONALITY_RESULTS_PAGE.path}
        />
        <TileWithIcon title="orientation.talent_passport_card" icon="suitcase" href={TALENT_PASSPORT_PAGE.path} />
        {/* <TileWithIcon
          title="orientation.trends_and_insights_card"
          icon="fire"
          href={TRENDS_AND_INSIGHTS_PAGE.path}
        /> */}
        <TileWithIcon title="orientation.toolbox_card" icon="wand" href={TOOLBOX_PAGE.path} />
      </div>

      {/* <h2 className="mb-4 mt-8 flex items-center text-2xl font-medium">
        {t('orientation.upskill_yourself')}
        <div className="ml-2 flex h-4 w-max items-center rounded-xl bg-gray-400 px-2 text-center text-[10px] text-[#1a0716]">
          Coming Soon
        </div>
      </h2>

      <div className="grid grid-rows-1 gap-6 lg:grid-cols-2 xl:grid-cols-3">
        <TileWithIcon title="orientation.find_training_card" isDisabled icon="degree-hat" href="/" />
      </div> */}

      <Separator className="mb-2 mt-6" />

      <Heading type="h2" font="body" weight="medium" className="mb-2">
        {t('orientation.feedback.subtitle')}
      </Heading>

      <p className="prose mb-4 text-gray-500">{t('orientation.feedback.description')}</p>

      <div className="flex justify-end">
        <Button
          size="lg"
          variant="outline"
          className="w-full !rounded-full md:w-fit md:!rounded-lg"
          onClick={() => openFeedbackModal('general')}
        >
          {t('orientation.feedback.button')}
        </Button>
      </div>

      <Separator className="mb-2 mt-2" />

      <Heading type="h2" font="body" weight="medium" className="mb-2">
        {t('orientation.redo_subtitle')}
      </Heading>

      <p className="prose prose-sm mb-4 text-gray-500">{t('orientation.redo_description')}</p>

      <div className="flex justify-end">
        <Dialog open={isModalOpen} onOpenChange={toggleIsModalOpen}>
          <DialogTrigger asChild>
            <Button
              size="lg"
              variant="outline"
              className="w-full !rounded-full md:w-fit md:!rounded-lg"
              onClick={() => {
                toggleIsModalOpen(true);
              }}
              data-testid="redo-button"
            >
              {t('orientation.redo_button')}
            </Button>
          </DialogTrigger>

          <DialogContent>
            <DialogTitle>{t('orientation.redo_modal_title')}</DialogTitle>

            <p className="prose text-primary">{t('orientation.redo_description')}</p>

            <DialogFooter>
              <div className="flex justify-end gap-2">
                <Button variant="outline" onClick={() => toggleIsModalOpen(false)}>
                  {t('orientation.redo_cancel_button')}
                </Button>
                <Button
                  data-testid="redo-confirm-button"
                  variant="destructive"
                  onClick={handleResetConfirm}
                  isLoading={resetAssessmentsMutation.isLoading}
                >
                  {t('orientation.redo_confirm_button')}
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
