import cx from 'clsx';
import { useAuth } from 'oidc-react';
import { AnimatePresence, m } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';

import { Icon, IconProps, FullScreenMenu, Avatar } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';
import { useUserProfile } from 'services/api';
import { useFeedbackModal } from 'services/feedback';
import { Authorize } from 'components/Authorize';
import { handleKeyDown } from 'utils/elements';
import { CLASS_STUDENT_DETAILS_PAGE, MY_CLASSES_PAGE, MY_STUDENTS_PAGE, SINGLE_ORGANIZATION_PAGE } from 'consts/pages';
import { useFocusTrap, useLockBodyScroll, useToggle } from 'utils/hooks';
import { getDefaultImage, getImageAlt } from 'utils/profile';

import { LanguagePicker } from './LanguagePicker';
import { NAVIGATION_ITEMS } from './navigation.config';

type NavItemProps = {
  icon: IconProps['name'];
  label: string;
  className?: string;
  isComingSoon?: boolean;
  isActive?: boolean;
  dataTestId?: string;
  onClick: () => void;
  isDesktopMenuExpanded?: boolean;
};

const NavItem = ({ icon, label, dataTestId, isComingSoon, isActive, onClick, className }: NavItemProps) => {
  const handleClick = () => {
    if (isComingSoon) {
      return;
    }

    onClick();
  };

  return (
    <div
      tabIndex={isComingSoon ? -1 : 0}
      role="button"
      data-testid={dataTestId}
      onClick={handleClick}
      onKeyDown={handleKeyDown(handleClick)}
      className={cx(
        'relative flex w-full cursor-pointer flex-col items-center justify-center rounded-sm px-2 py-2',
        {
          'pointer-events-none': isComingSoon,
          'text-navigation-accent font-medium': isActive,
          'opacity-50 grayscale': isComingSoon,
        },
        className,
      )}
    >
      <Icon name={icon} className="min-h-[1.5rem] min-w-[1.5rem] " />

      <div className="2xs:whitespace-nowrap whitespace-normal text-center text-sm leading-4">{label}</div>
    </div>
  );
};

const FullScreenMenuNavItem = ({
  icon,
  className,
  isComingSoon,
  isActive,
  isFullWidth = true,
  label,
  dataTestId,
  onClick,
}: NavItemProps & { isFullWidth?: boolean }) => {
  const handleClick = () => {
    if (isComingSoon) {
      return;
    }

    onClick();
  };

  return (
    <m.div
      tabIndex={isComingSoon ? -1 : 0}
      role="button"
      onClick={handleClick}
      onKeyDown={handleKeyDown(handleClick)}
      aria-label={label}
      data-testid={dataTestId}
      className={cx(
        'ring-navigation-accent flex w-full flex-row items-center gap-3 rounded-sm p-2 text-2xl focus:ring-2',
        {
          'text-navigation-accent font-medium': isActive,
          'pointer-events-none': isComingSoon,
          'w-max': !isFullWidth,
        },
        className,
      )}
    >
      <Icon name={icon} className="text-3xl" />

      <div className="relative">
        {label}
        {isComingSoon && (
          <div className="absolute -right-24 top-1/2 flex h-5 -translate-y-1/2 items-center rounded-xl bg-gray-400 px-2 text-[10px] text-[#1a0716]">
            Coming Soon
          </div>
        )}
      </div>
    </m.div>
  );
};

type FullMobileMenuProps = {
  isOpen: boolean;
  toggleOpen: (isOpen?: boolean) => void;
  onItemClick: (to?: string) => void;
  onLogout: () => void;
};

const FullMobileMenu = ({ isOpen, toggleOpen, onItemClick, onLogout }: FullMobileMenuProps) => {
  const t = useTranslate();
  const { data: userProfile } = useUserProfile();
  const { openFeedbackModal } = useFeedbackModal();
  const focusTrapRef = useFocusTrap<HTMLDivElement>(isOpen);
  const location = useLocation();
  useLockBodyScroll(isOpen);

  if (!userProfile) {
    return null;
  }

  const handleSupportClick = () => {
    openFeedbackModal('general');
    toggleOpen(false);
  };

  return (
    <FullScreenMenu
      ref={focusTrapRef}
      isOpen={isOpen}
      className="flex flex-col overflow-auto p-4"
      onClose={() => toggleOpen(false)}
      dataTestId="full-screen-menu"
    >
      <div className="mb-4 mt-4 flex items-center gap-2">
        <Avatar
          isRounded
          size="medium"
          src={userProfile.profilePicture || getDefaultImage(userProfile.firstName, userProfile.lastName)}
          alt={getImageAlt(userProfile.firstName, userProfile.lastName)}
        />

        <img src="/images/logotype.svg" className="w-44" />
      </div>

      <div className="flex-1 space-y-4">
        <AnimatePresence>
          {NAVIGATION_ITEMS.map((item, idx) => {
            if ('path' in item.page) {
              const path = item.page.path;

              let isActive: boolean;

              if (path === MY_CLASSES_PAGE.path) {
                if (userProfile.roles.includes('COACH')) {
                  isActive =
                    (location.pathname.startsWith('/organizations') &&
                      !(location.state as Record<string, boolean> | undefined)?.fromMyStudents) ||
                    location.pathname === MY_CLASSES_PAGE.path;
                } else {
                  isActive = path === MY_CLASSES_PAGE.path;
                }
              } else {
                isActive = location.pathname.startsWith(path);
              }

              if (CLASS_STUDENT_DETAILS_PAGE.matchPath.test(location.pathname)) {
                if (
                  item.page.path.startsWith('/organizations') &&
                  (location.state as Record<string, boolean> | undefined)?.fromMyStudents
                ) {
                  isActive = false;
                }

                if (
                  item.page === MY_STUDENTS_PAGE &&
                  (location.state as Record<string, boolean> | undefined)?.fromMyStudents
                ) {
                  isActive = true;
                }
              }

              return (
                <Authorize key={idx} policy={item.policy}>
                  <m.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 * idx }}
                  >
                    <FullScreenMenuNavItem
                      onClick={() => onItemClick(path)}
                      dataTestId={`nav-item-${path}`}
                      isComingSoon={item.isComingSoon}
                      isActive={isActive}
                      icon={item.page.iconName}
                      label={t(item.page.menuLabelTKey)}
                    />
                  </m.div>
                </Authorize>
              );
            }
            const path = item.page.getPath(userProfile);
            let isActive: boolean;

            if (path === MY_CLASSES_PAGE.path) {
              if (userProfile.roles.includes('COACH')) {
                isActive =
                  (location.pathname.startsWith('/organizations') &&
                    !(location.state as Record<string, boolean> | undefined)?.fromMyStudents) ||
                  location.pathname === MY_CLASSES_PAGE.path;
              } else {
                isActive = path === MY_CLASSES_PAGE.path;
              }
            } else {
              isActive = location.pathname.startsWith(path);
            }

            if (CLASS_STUDENT_DETAILS_PAGE.matchPath.test(location.pathname)) {
              if (
                item.page === SINGLE_ORGANIZATION_PAGE &&
                (location.state as Record<string, boolean> | undefined)?.fromMyStudents
              ) {
                isActive = false;
              }
            }

            return (
              <Authorize key={idx} policy={item.policy}>
                <m.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 * idx }}
                >
                  <FullScreenMenuNavItem
                    onClick={() => onItemClick(path)}
                    dataTestId={`nav-item-${path}`}
                    isComingSoon={item.isComingSoon}
                    isActive={isActive}
                    icon={item.page.iconName}
                    label={t(item.page.menuLabelTKey)}
                  />
                </m.div>
              </Authorize>
            );
          })}

          <m.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 * NAVIGATION_ITEMS.length }}
          >
            <FullScreenMenuNavItem icon="support" label={t('navigation.support')} onClick={handleSupportClick} />
          </m.div>
        </AnimatePresence>
      </div>

      <m.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.1 * (NAVIGATION_ITEMS.length + 1) }}
      >
        <LanguagePicker className="-ml-2.5 mb-2 mt-2 max-w-max" />
      </m.div>

      <m.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.1 * (NAVIGATION_ITEMS.length + 2) }}
      >
        <FullScreenMenuNavItem isFullWidth={false} icon="logout" label={t('navigation.logout')} onClick={onLogout} />
      </m.div>
    </FullScreenMenu>
  );
};

export const MobileNavigation = () => {
  const { signOutRedirect } = useAuth();
  const t = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileNavMenuOpen, toggleIsMobileNavMenuOpen] = useToggle();
  const { data: userProfile } = useUserProfile();

  const handleMenuItemClick = (to: string) => {
    toggleIsMobileNavMenuOpen(false);

    navigate(to);
  };

  const handleLogout = async () => {
    await signOutRedirect();
  };

  return (
    <nav className="bg-brand fixed bottom-0 left-0 z-50 flex h-[65px] w-full justify-between overflow-hidden shadow-[0px_2px_10px] shadow-gray-800 transition-all duration-300 ease-in-out">
      <FullMobileMenu
        isOpen={isMobileNavMenuOpen}
        toggleOpen={toggleIsMobileNavMenuOpen}
        onItemClick={handleMenuItemClick}
        onLogout={handleLogout}
      />

      {NAVIGATION_ITEMS.filter((item) => item.isOnMainMenu).map((item) => {
        if ('path' in item.page) {
          const path = item.page.path;

          let isActive: boolean;

          if (path === MY_CLASSES_PAGE.path) {
            if (userProfile?.roles.includes('COACH')) {
              isActive = location.pathname.startsWith('/organizations') || location.pathname === MY_CLASSES_PAGE.path;
            } else {
              isActive = path === MY_CLASSES_PAGE.path;
            }
          } else {
            isActive = path === '/' ? location.pathname === '/' : location.pathname.startsWith(path);
          }

          return (
            <Authorize key={item.page.menuLabelTKey} policy={item.policy}>
              <NavItem
                isComingSoon={item.isComingSoon}
                isActive={isActive}
                icon={item.page.iconName}
                dataTestId={`nav-item-${path}`}
                label={t(item.page.menuLabelTKey)}
                onClick={() => handleMenuItemClick(path)}
              />
            </Authorize>
          );
        }
        const path = item.page.getPath(userProfile!);

        let isActive: boolean;

        if (path === '/') {
          isActive = location.pathname === '/';

          if (userProfile?.roles.includes('COACH')) {
            isActive = location.pathname.startsWith('/organizations') || location.pathname === '/';
          } else {
            isActive = location.pathname === '/';
          }
        } else {
          isActive = location.pathname.startsWith(path);
        }

        return (
          <Authorize key={item.page.menuLabelTKey} policy={item.policy}>
            <NavItem
              isComingSoon={item.isComingSoon}
              isActive={isActive}
              icon={item.page.iconName}
              dataTestId={`nav-item-${path}`}
              label={t(item.page.menuLabelTKey)}
              onClick={() => handleMenuItemClick(path)}
            />
          </Authorize>
        );
      })}

      <NavItem
        isActive={false}
        icon="horizontal-dots"
        dataTestId="nav-item-more"
        label={t('navigation.more')}
        onClick={() => toggleIsMobileNavMenuOpen(true)}
      />
    </nav>
  );
};
