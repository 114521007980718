import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { AnimatePresence, LazyMotion, domAnimation } from 'framer-motion';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { AuthProvider, AuthProviderProps } from 'oidc-react';
import {
  CAREER_ORIENTATION_PAGE,
  CAREER_ORIENTATION_PERSONALITY_RESULTS_PAGE,
  PERSONALITY_SURVEY_PAGE,
  SETTINGS_CHANGE_EMAIL_PAGE,
  SETTINGS_CHANGE_PASSWORD_PAGE,
  SETTINGS_DELETE_ACCOUNT_PAGE,
  SETTINGS_PAGE,
  EXPERIENCE_SURVEY_PAGE,
  MOTIVATIONS_SURVEY_PAGE,
  TRENDS_AND_INSIGHTS_PAGE,
  TALENT_PASSPORT_PAGE,
  TOOLBOX_PAGE,
  USER_MANAGEMENT_PAGE,
  ALL_SET_PAGE,
  ALL_ORGANIZATIONS_PAGE,
  SINGLE_ORGANIZATION_PAGE,
  CLASS_DETAILS_PAGE,
  CLASS_STUDENT_DETAILS_PAGE,
  MY_STUDENTS_PAGE,
  MY_CLASSES_PAGE,
  MENTORS_PAGE,
} from 'consts/pages';
import { Root } from 'routes/Root';
import { HomePage } from 'routes/HomePage';
import { PortalRoot } from 'routes/PortalRoot';
import { AuthCallbackPage } from 'routes/AuthCallbackPage';
import { AccountSettingsPage } from 'routes/AccountSettingsPage';
import { ChangeEmailPage } from 'routes/AccountSettingsPage/routes/ChangeEmailPage';
import { DeleteAccountPage } from 'routes/AccountSettingsPage/routes/DeleteAccountPage';
import { ChangePasswordPage } from 'routes/AccountSettingsPage/routes/ChangePasswordPage';
import { PersonalitySurveyResultsPage } from 'routes/CareerOrientationPage/routes/PersonalitySurveyResultsPage';
import { ExperienceSurveyPage } from 'routes/CareerOrientationPage/routes/ExperienceSurveyPage';
import { MotivationSurveyPage } from 'routes/CareerOrientationPage/routes/MotivationSurveyPage';
import { TrendsAndInsightsPage } from 'routes/CareerOrientationPage/routes/TrendsAndInsightsPage';
import { TalentPassportPage } from 'routes/CareerOrientationPage/routes/TalentPassportPage';
import { ToolboxPage } from 'routes/CareerOrientationPage/routes/ToolboxPage';
import { PersonalitySurveyPage } from 'routes/CareerOrientationPage/routes/PersonalitySurveyPage';
import { CareerOrientationPage } from 'routes/CareerOrientationPage';
import { AllSetPage } from 'routes/CareerOrientationPage/routes/AllSetPage';
import { MentorsPage } from 'routes/MentorsPage';

import './fonts.css';
import './globals.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60, // 1 hour
      retry: 1,
    },
  },
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/auth/callback',
        element: <AuthCallbackPage />,
      },
      {
        path: '/auth/logout',
        element: <div>Logging out...</div>,
      },
      {
        path: '/',
        element: <PortalRoot />,
        children: [
          {
            path: '/',
            element: <HomePage />,
          },

          {
            path: USER_MANAGEMENT_PAGE.path,
            async lazy() {
              const { UserManagementPage } = await import('routes/UserManagementPage');

              return {
                Component: UserManagementPage,
              };
            },
          },
          {
            path: SETTINGS_PAGE.path,
            element: <AccountSettingsPage />,
          },
          {
            path: SETTINGS_CHANGE_EMAIL_PAGE.path,
            element: <ChangeEmailPage />,
          },
          {
            path: SETTINGS_CHANGE_PASSWORD_PAGE.path,
            element: <ChangePasswordPage />,
          },
          {
            path: SETTINGS_DELETE_ACCOUNT_PAGE.path,
            element: <DeleteAccountPage />,
          },
          {
            path: CAREER_ORIENTATION_PAGE.path,
            element: <CareerOrientationPage />,
          },
          {
            path: PERSONALITY_SURVEY_PAGE.path,
            element: <PersonalitySurveyPage />,
          },
          {
            path: CAREER_ORIENTATION_PERSONALITY_RESULTS_PAGE.path,
            element: <PersonalitySurveyResultsPage />,
          },
          {
            path: EXPERIENCE_SURVEY_PAGE.path,
            element: <ExperienceSurveyPage />,
          },
          {
            path: MOTIVATIONS_SURVEY_PAGE.path,
            element: <MotivationSurveyPage />,
          },
          {
            path: ALL_SET_PAGE.path,
            element: <AllSetPage />,
          },
          {
            path: TRENDS_AND_INSIGHTS_PAGE.path,
            element: <TrendsAndInsightsPage />,
          },
          {
            path: TALENT_PASSPORT_PAGE.path,
            element: <TalentPassportPage />,
          },
          {
            path: TOOLBOX_PAGE.path,
            element: <ToolboxPage />,
          },
          {
            path: MENTORS_PAGE.path,
            element: <MentorsPage />,
          },
          {
            path: ALL_ORGANIZATIONS_PAGE.path,
            async lazy() {
              const { OrganizationsPage } = await import('routes/OrganizationsPage');

              return {
                Component: OrganizationsPage,
              };
            },
          },
          {
            path: SINGLE_ORGANIZATION_PAGE.routerPath,
            async lazy() {
              const { SingleOrganizationPage } = await import('routes/OrganizationsPage/routes/SingleOrganizationPage');

              return {
                Component: SingleOrganizationPage,
              };
            },
          },
          {
            path: MY_STUDENTS_PAGE.path,
            async lazy() {
              const { MyStudentsPage } = await import('routes/MyStudentsPage');

              return {
                Component: MyStudentsPage,
              };
            },
          },
          {
            path: CLASS_DETAILS_PAGE.routerPath,
            async lazy() {
              const { ClassDetailsPage } = await import('routes/ClassDetailsPage');

              return {
                Component: ClassDetailsPage,
              };
            },
          },
          {
            path: CLASS_STUDENT_DETAILS_PAGE.routerPath,
            async lazy() {
              const { ClassStudentDetailsPage } = await import('routes/ClassStudentDetailsPage');

              return {
                Component: ClassStudentDetailsPage,
              };
            },
          },
          {
            path: MY_CLASSES_PAGE.path,
            async lazy() {
              const { MyClassesPage } = await import('routes/MyClassesPage');

              return {
                Component: MyClassesPage,
              };
            },
          },
        ],
      },
    ],
  },
]);

const { origin } = window.location;

import.meta.env.PROD &&
  Sentry.init({
    dsn: 'https://2e592c5e23d70d849622d5daffaae738@o4505958922715136.ingest.sentry.io/4505960021884928',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', /^https:\/\/api\.interskillar\.be\/api/],
      }),
      new Sentry.Replay(),
    ],
    ignoreErrors: [/No matching state/i],
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

const oidcConfig: AuthProviderProps = {
  authority: import.meta.env.VITE_BACKEND_URL,
  clientId: import.meta.env.VITE_OIDC_CLIENT_ID,
  scope: import.meta.env.VITE_OIDC_SCOPE,
  autoSignIn: true,
  redirectUri: `${origin}/auth/callback`,
  postLogoutRedirectUri: `${origin}/auth/logout`,
  onSignIn: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  onBeforeSignIn: () => {
    return JSON.stringify({ redirectTo: `${window.location.pathname}${window.location.search || ''}` });
  },
};

window.addEventListener('unhandledrejection', (event) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  if (event?.reason?.message?.includes('No matching state')) {
    window.location.href = '/';
  }
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <QueryClientProvider client={queryClient}>
        {import.meta.env.DEV && !0 && <ReactQueryDevtools position="top-right" />}

        <LazyMotion features={domAnimation}>
          <AnimatePresence mode="wait">
            <RouterProvider router={router} />
          </AnimatePresence>
        </LazyMotion>
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>,
);
// https://server.com/connect/authorize?response_type=token&client_id=s6BhdRkqt3&redirect_uri=https%3A%2F%2Fclient.example.org%2Fcb&scope=openid&state=af0ifjsldkj&nonce=n-0S6_WzA2Mj
