import { useEffect, useMemo } from 'react';
import * as z from 'zod';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, Control, useForm } from 'react-hook-form';

import { useTranslate } from '@interskillar/localization';
import { Checkbox, PageLoader, Collapse, Icon, Heading, ProgressBar, Button } from '@interskillar/ui';
import { useToggle } from 'utils/hooks';
import { setLatestSurvey } from 'utils/storage';
import { getStepProgress } from 'utils/surveys';
import { ALL_SET_PAGE, MOTIVATIONS_SURVEY_PAGE } from 'consts/pages';
import {
  useExperienceSurvey,
  useExperienceSurveyOptions,
  useUpdateExperienceSurvey,
  useUserProfile,
} from 'services/api';
import { ExpandButton } from 'components/ExpandButton';

import { SurveySteps } from '../../SurveySteps';

type ExperienceOptionProps = {
  isExpandable: boolean;
  id: string;
  label: string;
  description: string;
  control: Control<FormValues, unknown>;
  index: number;
  isDisabled?: boolean;
  fieldName: 'isSelected' | 'isFavorite';
};

const ExperienceOption = ({
  isExpandable = true,
  fieldName,
  id,
  index,
  label,
  description,
  control,
  isDisabled,
}: ExperienceOptionProps) => {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <div data-testid="experience-option">
      <div className="flex items-center justify-between">
        <Controller
          control={control}
          name={`experiences.${index}.${fieldName}`}
          render={({ field }) => {
            return (
              <Checkbox
                id={id + ''}
                isDisabled={isDisabled}
                size="medium"
                label={label}
                isChecked={field.value}
                {...field}
              />
            );
          }}
        />

        <div>{isExpandable && <ExpandButton isOpen={isOpen} onToggle={toggleIsOpen} />}</div>
      </div>
      <Collapse
        isOpen={isOpen}
        fallback={<div className="max-w-[50ch] overflow-hidden text-ellipsis whitespace-nowrap">{description}</div>}
      >
        {description}
      </Collapse>
    </div>
  );
};

type StepProps = {
  experiences: NonNullable<FormValues['experiences']>;
  control: Control<FormValues, unknown>;
  error?: string;
};

type FirstStepProps = StepProps & {
  onNextStep: () => void;
};

const FirstStep = ({ experiences, control, error, onNextStep }: FirstStepProps) => {
  const t = useTranslate();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(MOTIVATIONS_SURVEY_PAGE.path, {
      replace: true,
      state: {
        fromExperience: true,
      },
    });
  };

  return (
    <>
      <Heading isHighlighted font="body" className="mb-4 justify-center text-center">
        {t('orientation.experience_survey.title')}
      </Heading>

      <div className="space-y-4">
        {experiences.map((field, index) => (
          <ExperienceOption
            key={field.id}
            isExpandable
            fieldName="isSelected"
            id={field.id}
            control={control}
            index={index}
            description={field.description}
            label={field.label}
          />
        ))}
      </div>

      {error && <div className="mt-4 text-red-600">{error}</div>}

      <div className="mt-6 flex items-center gap-2 md:justify-center">
        <Button
          size="lg"
          variant="outline"
          className="w-full md:w-max"
          color="secondary"
          onClick={handleBack}
          data-testid="previous-button"
        >
          <Icon name="chevron-left" className="mr-2" />
          {t('orientation.experience_survey.previous_button')}
        </Button>

        <Button
          size="lg"
          variant="outline"
          className="w-full md:w-max"
          color="secondary"
          onClick={onNextStep}
          data-testid="next-button"
        >
          {t('orientation.experience_survey.next_button')}
          <Icon name="chevron-right" className="ml-2" />
        </Button>
      </div>
    </>
  );
};

type SecondStepProps = StepProps & {
  isLoading: boolean;
  onPreviousStep: () => void;
  onSubmit: () => void;
};

const SecondStep = ({ experiences, control, isLoading, error, onPreviousStep, onSubmit }: SecondStepProps) => {
  const t = useTranslate();

  const isDisabled = experiences.filter((experience) => experience.isFavorite).length >= 3;

  return (
    <>
      <Heading isHighlighted font="body" className="mb-4 justify-center text-center">
        {t('orientation.experience_survey.favorite_title')}
      </Heading>

      <p className="prose text-primary mb-4 text-sm">
        {t('orientation.experience_survey.second_step_validation_info')}
      </p>

      <div className="space-y-2">
        {experiences.map((field, index) => {
          if (!field.isSelected) {
            return null;
          }

          return (
            <ExperienceOption
              key={field.id}
              isExpandable
              fieldName="isFavorite"
              id={field.id}
              control={control}
              index={index}
              description={field.favoriteDescription}
              label={field.label}
              isDisabled={field.isFavorite ? false : isDisabled}
            />
          );
        })}
      </div>

      {error && <div className="mt-4 text-red-600">{error}</div>}

      <div className="mt-6 flex items-center gap-2 md:justify-center">
        <Button
          size="lg"
          className="w-full md:w-max"
          variant="outline"
          onClick={onPreviousStep}
          disabled={isLoading}
          data-testid="previous-button"
        >
          <Icon name="chevron-left" className="mr-2" />
          {t('orientation.experience_survey.previous_button')}
        </Button>
        <Button
          size="lg"
          className="w-full min-w-[100px] md:w-max"
          variant="outline"
          onClick={onSubmit}
          isLoading={isLoading}
          data-testid="submit-button"
        >
          {t('orientation.experience_survey.next_button')}
          <Icon name="chevron-right" className="ml-2" />
        </Button>
      </div>
    </>
  );
};

const validationSchema = z
  .object({
    step: z.literal(0).or(z.literal(1)),
    experiences: z.array(
      z.object({
        id: z.string(),
        isFavorite: z.boolean(),
        isSelected: z.boolean(),
        label: z.string(),
        description: z.string(),
        favoriteDescription: z.string(),
      }),
    ),
  })
  .superRefine((data, ctx) => {
    if (data.step === 0) {
      const hasSelectedExperience = data.experiences.some((experience) => experience.isSelected);
      if (!hasSelectedExperience) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'validation.select_at_least_one',
          path: ['experiences'],
        });
      }
    }

    if (data.step === 1) {
      const numberOfFavoriteExperiences = data.experiences.filter((experience) => experience.isFavorite).length;

      if (!numberOfFavoriteExperiences) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'validation.select_at_least_one',
          path: ['experiences'],
        });
      }

      if (numberOfFavoriteExperiences > 3) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'validation.select_at_most_three',
          path: ['experiences'],
        });
      }
    }
  });

type FormValues = z.infer<typeof validationSchema>;

export const ExperienceSurveyPage = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const userProfileQuery = useUserProfile();
  const experienceSurveyOptionsQuery = useExperienceSurveyOptions();
  const updateExperienceSurveyMutation = useUpdateExperienceSurvey();
  const experienceSurveyQuery = useExperienceSurvey();

  const userId = userProfileQuery.data!.id;

  useEffect(() => {
    setLatestSurvey(userId, 'experience');
  }, [userId]);

  const values = useMemo(() => {
    return {
      step: 0,
      experiences:
        experienceSurveyOptionsQuery.data?.map((option) => {
          const selectedOption = (experienceSurveyQuery.data?.experienceOptions || []).find(
            (selectedOption) => selectedOption.experienceOptionId === option.experienceOptionId,
          );

          const isFavorite = selectedOption?.isFavorite || false;

          return {
            id: option.experienceOptionId + '',
            isFavorite,
            isSelected: !!selectedOption,
            label: option.localizedTitle,
            description: option.localizedDescription,
            favoriteDescription: option.localizedFavoriteItemDescription,
          };
        }) || [],
    } as FormValues;
  }, [experienceSurveyOptionsQuery.data, experienceSurveyQuery.data?.experienceOptions]);

  const {
    control,
    watch,
    setValue,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    values,
    resolver: zodResolver(validationSchema),
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const experiences = watch('experiences')!;
  const step = watch('step')!;

  const handlePreviousStep = () => {
    clearErrors();
    setValue('step', 0);

    window.scrollTo(0, 0);
  };

  const handleNextStep = async () => {
    const isValid = await trigger();

    if (isValid) {
      setValue('step', 1);
    }

    window.scrollTo(0, 0);
  };

  const handleSubmit = async () => {
    const isValid = await trigger();

    if (isValid) {
      updateExperienceSurveyMutation.mutate(
        {
          experienceOptions: experiences
            .filter((experience) => experience.isSelected)
            .map((experience) => ({
              experienceOptionId: parseInt(experience.id),
              isFavorite: experience.isFavorite,
            })),
        },
        {
          onSuccess: () => {
            navigate(ALL_SET_PAGE.path);
          },
          onError: () => {
            toast.error(t('orientation.experience_survey.error_message'));
          },
        },
      );
    }
  };

  if (experienceSurveyQuery.isLoading || experienceSurveyOptionsQuery.isLoading || !experienceSurveyOptionsQuery.data) {
    return <PageLoader />;
  }

  return (
    <div className="max-w-xl pb-14">
      <SurveySteps step={2} initialAnimate />

      <ProgressBar className="mb-4" value={step === 0 ? getStepProgress(3) : getStepProgress(4)} duration={500} />

      {step === 0 && (
        <FirstStep
          experiences={experiences}
          control={control}
          onNextStep={handleNextStep}
          error={errors.experiences?.message && t(errors.experiences.message)}
        />
      )}
      {step === 1 && (
        <SecondStep
          experiences={experiences}
          control={control}
          onPreviousStep={handlePreviousStep}
          onSubmit={handleSubmit}
          isLoading={updateExperienceSurveyMutation.isLoading}
          error={errors.experiences?.message && t(errors.experiences.message)}
        />
      )}
    </div>
  );
};
