export const languages = ['en', 'fr-BE', 'nl-BE'] as const;

export type AvailableLanguages = (typeof languages)[number];

export const languagesImportMap: Record<AvailableLanguages, () => Promise<typeof import('../messages/en.json')>> = {
  en: async () => {
    return import('../messages/en.json').then((res) => res.default);
  },
  'fr-BE': async () => {
    return import('../messages/fr-BE.json').then((res) => res.default);
  },
  'nl-BE': async () => {
    return import('../messages/nl-BE.json').then((res) => res.default);
  },
};

export const DEFAULT_LANGUAGE = 'fr-BE';
