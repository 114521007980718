import * as React from 'react';
import { m } from 'framer-motion';
import cx from 'clsx';

import { Icon } from './Icon';
import { Button } from './Button';

export type FullScreenMenuProps = {
  ref?: React.Ref<HTMLDivElement>;
  children: React.ReactNode;
  isOpen: boolean;
  className?: string;
  dataTestId?: string;

  onClose: () => void;
};

export const FullScreenMenu = React.forwardRef<HTMLDivElement, FullScreenMenuProps>(
  ({ children, isOpen, className, dataTestId, onClose }, ref) => {
    React.useEffect(() => {
      const callback = (e: KeyboardEvent) => {
        if (e.key === 'Escape' && isOpen) {
          onClose();
        }
      };
      window.addEventListener('keydown', callback);

      return () => {
        window.removeEventListener('keydown', callback);
      };
    }, [isOpen, onClose]);

    return (
      <m.div
        variants={{
          open: {
            opacity: 1,
          },
          closed: {
            opacity: 0,
          },
        }}
        data-testid={dataTestId}
        animate={isOpen ? 'open' : 'closed'}
        ref={ref}
        tabIndex={isOpen ? undefined : -1}
        className={cx(
          'bg-brand fixed left-0 top-0 z-50 h-full w-full',
          {
            'pointer-events-none': !isOpen,
          },
          className,
        )}
      >
        {isOpen && (
          <>
            <Button
              aria-label="Close"
              className="absolute right-0 top-2"
              variant="ghost"
              tabIndex={isOpen ? undefined : -1}
              onClick={onClose}
            >
              <Icon name="close" className="text-4xl" />
            </Button>

            {children}
          </>
        )}
      </m.div>
    );
  },
);
