import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useTranslate } from '@interskillar/localization';
import { LeavePrompt } from 'components/LeavePrompt';
import { setLatestSurvey } from 'utils/storage';
import { Heading, Icon, PageLoader, ProgressBar, Button } from '@interskillar/ui';
import { MOTIVATIONS_SURVEY_PAGE } from 'consts/pages';
import {
  AnswerPersonalitySurveyQuestionBody,
  PersonalitySurveyQuestions,
  useAnswerPersonalitySurveyQuestion,
  usePersonalitySurveyQuestions,
  usePersonalitySurveyStatus,
  useStartPersonalitySurvey,
  useUserProfile,
} from 'services/api';

import { SurveySteps } from '../../SurveySteps';
import { SingleAnswerQuestion } from './Survey/SingleAnswerQuestion';

const emptyFlow = {} as PersonalitySurveyQuestions['questionFlows'];
const emptyQuestions = [];

export function PersonalitySurveyPage() {
  const t = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  const hasStartedRef = useRef(false);
  const hasSetToEndRef = useRef(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentAnswerId, setCurrentAnswerId] = useState<number | null>(null);
  const userProfileQuery = useUserProfile();

  const personalitySurveyStatusQuery = usePersonalitySurveyStatus();
  const personalitySurveyQuestions = usePersonalitySurveyQuestions();
  const answerPersonalitySurveyQuestionMutation = useAnswerPersonalitySurveyQuestion();
  const startPersonalitySurveyMutation = useStartPersonalitySurvey();

  const currentFlow = personalitySurveyStatusQuery.data?.progressInfo?.currentFlow || 0;
  const flows = personalitySurveyQuestions.data?.questionFlows || emptyFlow;
  const questions = flows[currentFlow] || emptyQuestions;
  const hasNext = currentQuestion < questions.length - 1;
  const hasPrevious = currentQuestion !== 0;
  const hasSubmit = !hasNext;
  const question = questions[currentQuestion];
  const userId = userProfileQuery.data!.id;
  const preselectedAnswer = personalitySurveyStatusQuery.data?.userSelectedAnswers?.[question?.questionId] || null;
  const isLoadingNext = answerPersonalitySurveyQuestionMutation.isLoading;

  useEffect(() => {
    setCurrentAnswerId(preselectedAnswer);
  }, [preselectedAnswer]);

  useEffect(() => {
    setLatestSurvey(userId, 'personality');
  }, [userId]);

  useEffect(() => {
    if ('fromMotivationSurvey' in (location.state || {}) && !hasSetToEndRef.current && questions?.length) {
      setCurrentQuestion(questions.length - 1);
      hasSetToEndRef.current = true;
    }
  }, [currentFlow, flows, location.state, questions]);

  const handleNextQuestion = (data: AnswerPersonalitySurveyQuestionBody) => {
    if (isLoadingNext) {
      return;
    }

    if (!data.answerId) {
      document.querySelector<HTMLInputElement>('input[data-testid="survey-answer-option"]')?.focus();

      return;
    }

    answerPersonalitySurveyQuestionMutation.mutate(
      {
        answerId: data.answerId,
        questionId: question.questionId,
      },
      {
        onSuccess: () => {
          if (hasSubmit) {
            handleSubmit();
          } else {
            setCurrentQuestion((prev) => prev + 1);
            setCurrentAnswerId(null);
          }
        },
        onError: () => {
          toast.error(t('orientation.personality_survey.error_answering_question'));
        },
      },
    );
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestion((prev) => prev - 1);
    setCurrentAnswerId(null);
  };

  const handleSubmit = () => {
    navigate(MOTIVATIONS_SURVEY_PAGE.path, {
      replace: true,
    });
  };

  useEffect(() => {
    if (hasStartedRef.current) {
      return;
    }

    if (personalitySurveyStatusQuery.data?.basicInfo.status === 'NotStarted') {
      startPersonalitySurveyMutation.mutate();
      hasStartedRef.current = true;
    }
  }, [personalitySurveyStatusQuery.data?.basicInfo.status, startPersonalitySurveyMutation]);

  if (personalitySurveyStatusQuery.isLoading || personalitySurveyQuestions.isLoading) {
    return <PageLoader />;
  }

  if (!userProfileQuery.data) {
    return null;
  }

  if (personalitySurveyStatusQuery.data?.basicInfo.status === 'Completed') {
    return null;
  }

  const maxPossibleQuestions = Math.max(
    ...Object.values(personalitySurveyQuestions.data!.questionFlows).map((flow) => flow!.length),
  );

  const progress = ((currentQuestion + 1) / Math.max(maxPossibleQuestions + 4, 1)) * 100;

  const onNextQuestion = (questionId: number, answerId: number) => {
    const answer = answerId || preselectedAnswer!;

    handleNextQuestion({
      answerId: answer,
      questionId: questionId,
    });
  };

  return (
    <div className="flex h-full max-w-xl flex-col items-center">
      <SurveySteps step={0} />

      <ProgressBar
        className="mb-8 mt-4"
        value={progress}
        ariaLabel={t('orientation.personality_survey.survey_progress')}
      />

      <Heading isHighlighted font="body" className="mb-6" dataTestId={`question-title-${currentQuestion + 1}`}>
        {t('orientation.survey.question_number', {
          value: currentQuestion + 1,
        })}
      </Heading>

      <LeavePrompt when={hasNext} message={t('orientation.personality_survey.leave_prompt')} />

      <div className="w-full flex-1 md:flex-initial">
        <SingleAnswerQuestion question={question} selectedAnswer={currentAnswerId} onNextQuestion={onNextQuestion} />
      </div>

      <div className="mb-20 mt-10 flex w-full flex-1 items-center justify-center gap-2 md:w-auto md:flex-initial">
        {hasPrevious && (
          <Button size="lg" className="flex-1 md:flex-auto" variant="outline" onClick={handlePreviousQuestion}>
            <Icon name="chevron-left" className="mr-2" />
            {t('orientation.survey.back')}
          </Button>
        )}

        {hasNext && (
          <Button
            size="lg"
            className="flex-1 md:flex-auto"
            variant="outline"
            isLoading={isLoadingNext}
            disabled={isLoadingNext}
            onClick={() =>
              handleNextQuestion({
                questionId: question.questionId,
                answerId: currentAnswerId!,
              })
            }
          >
            {t('orientation.survey.next')}
            <Icon name="chevron-right" className="ml-2" />
          </Button>
        )}

        {hasSubmit && (
          <Button
            size="lg"
            variant="outline"
            className="flex-1 md:flex-auto"
            onClick={handleSubmit}
            isLoading={isLoadingNext}
            disabled={!currentAnswerId}
            data-testid="complete-button"
          >
            {t('orientation.survey.next')}
            <Icon name="chevron-right" className="ml-2" />
          </Button>
        )}
      </div>
    </div>
  );
}
