import * as React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import { getInitialLanguage } from './utils';
import { useTranslations } from './useTranslations';
import { DEFAULT_LANGUAGE, languagesImportMap, AvailableLanguages } from './consts';

const initialLanguage = getInitialLanguage();

type Context = {
  language: AvailableLanguages;
  mappedLanguage: string;
  changeLanguage: (language: AvailableLanguages) => void;
};
const noop = () => void 0;

const LANGUAGE_CODE_MAPPING: Record<AvailableLanguages, string> = {
  en: 'en',
  'fr-BE': 'fr',
  'nl-BE': 'nl',
};

export const IntlState = React.createContext<Context>({
  language: initialLanguage,
  mappedLanguage: LANGUAGE_CODE_MAPPING[initialLanguage],
  changeLanguage: noop,
});

export type IntlProviderProps = {
  children: React.ReactNode;
};

export const IntlProvider = ({ children }: IntlProviderProps) => {
  const [language, setLanguage] = React.useState(initialLanguage);

  const translationsQuery = useTranslations(language);

  const changeLanguage = React.useCallback((language: AvailableLanguages) => {
    let lang: AvailableLanguages = DEFAULT_LANGUAGE;
    if (!languagesImportMap[language]) {
      if (import.meta.env.DEV) {
        throw new Error(`Language "${language}" is not supported`);
      }

      lang = DEFAULT_LANGUAGE;
    } else {
      lang = language;
    }

    setLanguage(lang);
    localStorage.setItem('lang', lang);
  }, []);

  const ctxValue = React.useMemo(() => {
    return {
      language,
      mappedLanguage: LANGUAGE_CODE_MAPPING[language],
      changeLanguage,
    };
  }, [language, changeLanguage]);

  React.useEffect(() => {
    document.documentElement.lang = language;
  }, [language]);

  return (
    <IntlState.Provider value={ctxValue}>
      <ReactIntlProvider
        messages={translationsQuery.data ?? {}}
        locale={initialLanguage}
        defaultLocale={DEFAULT_LANGUAGE}
      >
        {children}
      </ReactIntlProvider>
    </IntlState.Provider>
  );
};
