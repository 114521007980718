import * as React from 'react';
import { toast } from 'react-toastify';

import { Avatar, Button } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';
import { getDefaultImage } from 'utils/profile';
import { useUpdateProfilePicture, type UserProfile } from 'services/api';

export type ProfilePictureSectionProps = {
  profile: UserProfile;
};

export const ProfilePictureSection = ({ profile }: ProfilePictureSectionProps) => {
  const t = useTranslate();
  const ref = React.useRef<HTMLInputElement>(null);
  const updateProfilePictureMutation = useUpdateProfilePicture();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    const formData = new FormData();

    formData.append('model', file);

    try {
      await updateProfilePictureMutation.mutateAsync(formData);

      toast.success(t('settings.update_photo.success_message'));
    } catch (error) {
      toast.error(t('settings.update_photo.error_message'));
    }
  };

  return (
    <div className="flex flex-col items-center justify-center space-y-4">
      <Avatar
        src={profile.profilePicture || getDefaultImage(profile.firstName || 'I', profile.lastName || 'S')}
        alt="Profile image"
        size="large"
      />
      <input ref={ref} type="file" accept="image/*" onChange={handleFileChange} hidden />

      <Button
        className="min-w-[155px]"
        variant="green"
        isLoading={updateProfilePictureMutation.isLoading}
        data-testid="update-profile-picture-button"
        onClick={() => {
          if (ref.current) {
            ref.current.click();
          }
        }}
      >
        {t('settings.update_photo.button_text')}
      </Button>
    </div>
  );
};
