import { Collapse, Icon } from '@interskillar/ui';
import { useTranslate } from '@interskillar/localization';
import { ExpandButton } from 'components/ExpandButton';
import { useToggle } from 'utils/hooks';

export const HowToRead = () => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const t = useTranslate();

  return (
    <div className="prose mb-2 rounded-2xl border border-gray-400 shadow-[0px_2px_10px] shadow-gray-800">
      <div
        className="grid grid-cols-[50px_1fr_50px] items-center px-4 py-3 pr-2"
        role="button"
        onClick={() => toggleIsOpen()}
      >
        <Icon name="bulb" className="text-brand-blue min-w-[2rem]" />
        <div className="text-primary font-medium">
          {t('orientation.personality_survey.results.your_personality.how_to_read')}
        </div>
        <ExpandButton className="text-primary w-[50px] !px-2" isOpen={isOpen} onToggle={() => {}} />
      </div>

      <Collapse isOpen={isOpen}>
        <div className="text-primary whitespace-pre-wrap p-4">
          {t('orientation.personality_survey.results.your_personality.global_description_subtitle')}
        </div>
      </Collapse>
    </div>
  );
};
