import { useTranslate } from '@interskillar/localization';
import { Heading, Icon } from '@interskillar/ui';
import { PersonalitySurveyResults } from 'services/api';

export const AttentionPoints = ({
  attentionPoints,
}: {
  attentionPoints: PersonalitySurveyResults['attentionPoints'];
}) => {
  const t = useTranslate();

  return (
    <div>
      <Heading iconName="attention-points" className="mb-4" type="h2" font="body">
        {t('orientation.personality_survey.results.attention_points.title')}
      </Heading>

      <p className="prose text-primary mb-4">
        {t('orientation.personality_survey.results.attention_points.description')}
      </p>

      <div className="space-y-8">
        {attentionPoints.map((attentionPoint) => (
          <div key={attentionPoint.id} className="flex">
            <Icon name="tip-icon" className="text-brand-pink mr-2 mt-1 min-w-[24px] text-2xl" />
            <div className="prose text-primary">
              <div className="mb-3">{attentionPoint.localizedDescription}</div>
              <div className="text-brand-pink mb-1 font-bold">
                {t('orientation.personality_survey.results.attention_points.tip')}
              </div>
              <div className="text-primary font-medium">{attentionPoint.localizedTip}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
