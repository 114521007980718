import { useCallback, useEffect, useRef } from 'react';
import { useBeforeUnload, unstable_useBlocker as useBlocker } from 'react-router-dom';

function usePrompt(message: string | null | undefined | false, { beforeUnload }: { beforeUnload?: boolean } = {}) {
  const blocker = useBlocker(
    useCallback(() => {
      if (typeof message === 'string') {
        return !window.confirm(message);
      }

      return false;
    }, [message]),
  );
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (beforeUnload && typeof message === 'string') {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message, beforeUnload],
    ),
    { capture: true },
  );
}

type PromptProps = {
  when: boolean;
  message: string;
  beforeUnload?: boolean;
};

export function LeavePrompt({ when, message, ...props }: PromptProps) {
  usePrompt(when ? message : false, props);

  return null;
}
