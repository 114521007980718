import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { useDeleteAccount } from 'services/api';
import {
  Heading,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormField,
  Input,
  FormMessage,
  Button,
} from '@interskillar/ui';
import { FormattedMessage, useTranslate } from '@interskillar/localization';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { SETTINGS_PAGE } from 'consts/pages';

const validationSchema = z.object({
  deleteInput: z.string().refine((value) => value === 'delete', {
    message: 'settings.delete_account.confirm_input_word_error',
  }),
});

type FormValues = z.infer<typeof validationSchema>;

export const DeleteAccountPage = () => {
  const t = useTranslate();
  const deleteAccountMutation = useDeleteAccount();

  const form = useForm<FormValues>({
    defaultValues: {
      deleteInput: '',
    },
    resolver: zodResolver(validationSchema),
  });

  const handleFormSubmit = () => {
    deleteAccountMutation.mutate();
  };

  return (
    <div className="max-w-xl space-y-4 pb-14">
      <Breadcrumbs
        className="mb-4"
        breadcrumbs={[
          {
            label: 'breadcrumbs.settings',
            href: SETTINGS_PAGE.path,
          },
          {
            label: 'breadcrumbs.delete_account',
            isCurrentPage: true,
          },
        ]}
      />

      <Heading iconName="heart-broken">{t('settings.delete_account.title')}</Heading>

      <p className="prose text-primary">
        <FormattedMessage
          id="settings.delete_account.description"
          values={{
            br: () => <br />,
            strong: (chunks) => <strong className="text-red-600">{chunks}</strong>,
          }}
        />
      </p>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleFormSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="deleteInput"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>{t('settings.delete_account.confirm_input_label')}</FormLabel>
                  <FormControl>
                    <Input
                      id="delete-input"
                      placeholder={t('settings.delete_account.confirm_input_placeholder')}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />

          <div className="flex justify-end">
            <Button
              type="submit"
              variant="destructive"
              isLoading={deleteAccountMutation.isLoading}
              disabled={!form.formState.isDirty}
              className="w-full md:w-fit md:min-w-[100px]"
            >
              {t('settings.delete_account.submit_button')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
