import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage, useIntlState, useTranslate } from '@interskillar/localization';
import { Heading, Icon, Button, PageLoader, Select, Spinner } from '@interskillar/ui';
import {
  useSectorsOfInterest,
  useSectorsOfInterestOptions,
  useSectorsOfInterestPreview,
  useUpdateSectorsOfInterest,
} from 'services/api';
import { EXPERIENCE_SURVEY_PAGE } from 'consts/pages';

type SectorsOfInterestProps = {
  onBack: () => void;
};

export const SectorsOfInterest = ({ onBack }: SectorsOfInterestProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { mappedLanguage } = useIntlState();
  const sectorsOfInterestQuery = useSectorsOfInterest();
  const sectorsOfInterestOptionsQuery = useSectorsOfInterestOptions();
  const [error, setError] = useState<string | null>(null);

  const [selectedPrimarySector, setSelectedPrimarySector] = useState<number | null>(null);
  const [selectedSecondarySector, setSelectedSecondarySector] = useState<number | null>(null);

  const sectorsOfInterestPreviewQuery = useSectorsOfInterestPreview(selectedPrimarySector, selectedSecondarySector);
  const updateSectorsOfInterestMutation = useUpdateSectorsOfInterest();

  useEffect(() => {
    if (sectorsOfInterestQuery.data) {
      setSelectedPrimarySector(sectorsOfInterestQuery.data.primarySectorId);
      setSelectedSecondarySector(sectorsOfInterestQuery.data.secondarySectorId || null);
    }
  }, [sectorsOfInterestQuery.data]);

  if (
    sectorsOfInterestQuery.isLoading ||
    sectorsOfInterestOptionsQuery.isLoading ||
    !sectorsOfInterestOptionsQuery.data
  ) {
    return <PageLoader />;
  }

  const handleUpdateSectorsOfInterest = () => {
    if (!selectedPrimarySector) {
      setError('orientation.sectors.please_select_sector');
      return;
    }

    updateSectorsOfInterestMutation.mutate(
      {
        languageCode: mappedLanguage,
        primaryInterestSectorId: selectedPrimarySector,
        secondaryInterestSectorId: selectedSecondarySector,
      },
      {
        onSuccess: () => {
          navigate(EXPERIENCE_SURVEY_PAGE.path, {
            replace: true,
          });
        },
        onError: () => {
          toast.error(t('orientation.sectors.error_message'));
        },
      },
    );
  };

  const handleChangePrimarySector = (option: (typeof OPTIONS)[number]) => {
    setSelectedPrimarySector(option.value);
    setError(null);
  };

  const handleChangeSecondarySector = (option: (typeof OPTIONS)[number] | null) => {
    if (option) {
      setSelectedSecondarySector(option.value);
    } else {
      setSelectedSecondarySector(null);
    }
  };

  const OPTIONS = sectorsOfInterestOptionsQuery.data.map((s) => ({
    label: s.localizedTitle,
    value: s.interestSectorId,
  }));
  const FILTERED_OPTIONS = OPTIONS.filter(
    (s) => s.value !== selectedPrimarySector && s.value !== selectedSecondarySector,
  );

  const primarySectorValue = OPTIONS.find((o) => o.value === selectedPrimarySector);
  const secondarySectorValue = OPTIONS.find((o) => o.value === selectedSecondarySector);

  return (
    <>
      <Heading isHighlighted font="body" className="mb-4 justify-center text-center">
        {t('orientation.sectors.title')}
      </Heading>

      <div className="space-y-4">
        <div>
          <Select
            dataTestId="primary-sector-select"
            label={t('orientation.sectors.sector_1_label')}
            placeholder={t('orientation.sectors.select_sector_placeholder')}
            options={FILTERED_OPTIONS}
            value={primarySectorValue}
            onChange={handleChangePrimarySector}
          />
        </div>

        <div>
          <Select
            dataTestId="secondary-sector-select"
            label={t('orientation.sectors.sector_2_label')}
            placeholder={t('orientation.sectors.select_sector_placeholder')}
            isDisabled={!selectedPrimarySector}
            options={FILTERED_OPTIONS}
            value={secondarySectorValue}
            isClearable
            onChange={handleChangeSecondarySector}
          />
        </div>
      </div>

      <div className="mt-6 flex gap-2 md:justify-center">
        <Button size="lg" className="flex-1 md:flex-initial" variant="outline" onClick={onBack}>
          <Icon name="chevron-left" className="mr-2" />
          {t('general.back')}
        </Button>
        <Button
          size="lg"
          className="w-full flex-1 md:w-max md:flex-initial"
          variant="outline"
          data-testid="submit-button"
          onClick={handleUpdateSectorsOfInterest}
          isLoading={updateSectorsOfInterestMutation.isLoading}
        >
          {t('general.next')}
          <Icon name="chevron-right" className="ml-2" />
        </Button>
      </div>

      <div className="my-2 h-7 text-red-600">{error && <span data-testid="error-message">{t(error)}</span>}</div>

      <div>
        <div className="mb-4 text-lg font-medium">
          <FormattedMessage id="orientation.sectors.info_title" values={{ br: <br /> }} />
        </div>

        {primarySectorValue && (
          <>
            <div className="bg-brand-yellow text-contrast rounded-xl p-4">
              {/* <div className="mb-2 text-lg font-medium">{primarySectorValue?.label}</div> */}

              {sectorsOfInterestPreviewQuery.isLoading && (
                <div className="mb-4 flex w-full justify-center">
                  <Spinner />
                </div>
              )}

              <div>
                <ul className="list-disc pl-6">
                  {sectorsOfInterestPreviewQuery.data?.localizedDescription?.split('\n').map((description) => (
                    <li key={description}>{description}</li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
